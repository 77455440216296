import React from "react";

function Navbar() {
  return (
    <div>
      {" "}
      <header>
        <nav
          id="navbarSection"
          style={{ background: "#5C82DB" }}
          className="navbar navbar-expand-lg navbar-boxed navbar-dark fixed-top navbar-boxed navbar-static-overlay"
        >
          <div className="container-fluid">
            <div className="col-3 text-left">
              <a className="navbar-brand" href="/">
                <img style={{ maxWidth: "70%" }} src="img/logo.png" data-at2x="img/logo.png" className="default-logo" alt="" />
                <img style={{ maxWidth: "70%" }} src="img/logo.png" data-at2x="img/logo.png" className="alt-logo" alt="" />
                <img style={{ maxWidth: "70%" }} src="img/logo.png" data-at2x="img/logo.png" className="mobile-logo" alt="" />
              </a>
            </div>
            <div className="text-center col-6">
              <button
                className="navbar-toggler text-center"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav alt-font">
                  <li className="nav-item">
                    <a className="nav-link text-uppercase" href="#overview">
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-uppercase" href="#gallery">
                      Gallery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-uppercase" href="#amenities">
                      Amenities
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-uppercase" href="#landmark">
                      Location
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-uppercase" href="#floor">
                      Floor Plan
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-uppercase" href="#contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-3 text-right">
              <a className="navbar-brand" href="/">
                <img src="img/footer-logo.svg" data-at2x="img/footer-logo.svg" className="default-logo" alt="" />
                <img src="img/footer-logo.svg" data-at2x="img/footer-logo.svg" className="alt-logo" alt="" />
                <img src="img/footer-logo.svg" data-at2x="img/footer-logo.svg" className="mobile-logo" alt="" />
              </a>
            </div>
          </div>
        </nav>

        <div className="hamburger-menu hamburger-menu-half bg-extra-dark-gray lg-w-60 md-w-60 sm-w-100">
          <a href="javascript:void(0);" className="close-menu text-white">
            <i className="fas fa-times"></i>
          </a>
          <div className="d-flex flex-column align-items-center justify-content-center h-100 padding-6-rem-all md-padding-2-rem-lr xs-no-padding-lr">
            <a href="index.html" className="d-none d-lg-inline-block">
              <img src="img/logo.png" alt="" className="md-w-160px" />
            </a>
            <div className="col-12 col-xl-9 col-lg-8 col-md-9 col-sm-7 menu-list-wrapper margin-3-rem-tb" data-scroll-options='{ "theme": "light" }'>
              <ul className="menu-list alt-font w-70 xl-w-100 margin-auto-lr">
                <li className="menu-list-item">
                  <a href="/">Overview</a>
                </li>
                <li className="menu-list-item">
                  <a href="clubplan">Club Plan</a>
                </li>
                <li className="menu-list-item">
                  <a href="floorplan">Floor Plan</a>
                </li>
                <li className="menu-list-item">
                  <a href="masterplan">Master Plan</a>
                </li>
              </ul>
            </div>
            <div className="text-center elements-social social-icon-style-12 d-none d-md-inline-block">
              <ul className="small-icon light">
                <li>
                  <a className="facebook" href="https://www.facebook.com/" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a className="dribbble" href="http://www.dribbble.com" target="_blank">
                    <i className="fab fa-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a className="twitter" href="http://www.twitter.com" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a className="instagram" href="http://www.instagram.com" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a className="linkedin" href="http://www.linkedin.com" target="_blank">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;

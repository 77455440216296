import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import suryaLocationMap from "../img/SuryalocationMP.jpg";
import Modal from "./components/Modal";
import { useNavigate } from "react-router-dom";
function App() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const categories = [
    {
      title: "School/College",
      items: [
        "Presidency University - 10 mins",
        "Vidyasilip University - 20 mins",
        "Stonehill International School - 20 mins",
        "Nitte Meenakshi Institute of Technology",
        "Harrow International School - 30 mins",
      ],
    },
    {
      title: "Hospitals",
      items: [
        "Manipal Hospital North - 30 mins",
        "Cytecare Hospitals - 30 mins",
      ],
    },
    {
      title: "Transportation Connectivity",
      items: [
        "Kempegowda International Airport - 30 mins",
        "Yelahanka Railway Station",
      ],
    },
    {
      title: "Commercial",
      items: [
        "Prestige Tech Cloud - 20 mins",
        "Brigade Magnum - 30 mins",
        "L&T Tech Park - 30 mins",
      ],
    },
    {
      title: "Sports",
      items: ["Padukone Dravid Center for Excellence - 20 mins"],
    },
    {
      title: "Leisure & Entertainment",
      items: [
        "Spree Resort - 10 mins",
        "Silver Oak Resort - 10 mins",
        "Park Inn & Suites by Radisson - 10 mins",
        "Angsana Oasis Spa & Resort - 10 mins",
        "Ramanashree California Resort - 20 mins",
        "Dome Retreats - 10 mins",
        "Moxy Bengaluru Airport - 20 mins",
        "Rico Wine Park & Vineyards - 20 mins",
        "Terraform Arena - 20 mins",
        "Club Cabana - 30 mins",
        "Royal Orchid Resorts - 30 mins",
        "Hesaraghatta Grasslands Hiking - 30 mins",
        "Dmart - 30 mins",
        "Phoenix Mall of Asia - 30 mins",
        "Bloom Hotel - 30 mins",
      ],
    },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // UTM parameters state
  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_display: "",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Get standard UTM parameters
    const utmMedium = queryParams.get("utm_medium") || "";

    // Extract matchtype and keyword from utm_campaign
    let utmTerm = queryParams.get("utm_term") || "";

    // If utm_term is empty but utm_campaign exists
    if (!utmTerm && utmMedium) {
      // Try to extract the last two segments from the campaign
      // Assuming format like "FE_01_AG_01_Phrase_{matchtype}_{keyword}"
      const parts = utmMedium.split("_");

      // If we have at least 2 parts, take the last two
      if (parts.length >= 2) {
        utmTerm = `${parts[parts.length - 2]}_${parts[parts.length - 1]}`;
      }
    }

    const params = {
      utm_source: queryParams.get("utm_source") || "",
      utm_medium: utmMedium,
      utm_campaign: queryParams.get("utm_campaign") || "",
      utm_term: utmTerm,
      utm_display: queryParams.get("utm_display") || "",
      utm_adgroup: queryParams.get("utm_adgroup") || "",
    };

    setUtmParams(params);
  }, []);

  // Form states
  const [contactForm, setContactForm] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    message: "", // Added message field
  });

  const [enquiryForm, setEnquiryForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "Enquiry from modal form", // Default message for enquiry form
  });

  // Loading and success states
  const [contactLoading, setContactLoading] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);

  const [enquiryLoading, setEnquiryLoading] = useState(false);
  const [enquirySuccess, setEnquirySuccess] = useState(false);

  // Error states
  const [contactErrors, setContactErrors] = useState({});
  const [enquiryErrors, setEnquiryErrors] = useState({});

  // Handle contact form input changes
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });

    // Clear error when user starts typing
    if (contactErrors[name]) {
      setContactErrors({
        ...contactErrors,
        [name]: "",
      });
    }
  };

  // Handle enquiry form input changes
  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    setEnquiryForm({
      ...enquiryForm,
      [name]: value,
    });

    // Clear error when user starts typing
    if (enquiryErrors[name]) {
      setEnquiryErrors({
        ...enquiryErrors,
        [name]: "",
      });
    }
  };

  // Validate contact form
  const validateContactForm = () => {
    const errors = {};

    if (!contactForm.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }

    if (!contactForm.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(contactForm.email)) {
      errors.email = "Email is invalid";
    }

    if (!contactForm.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
    } else if (!/^\d{10}$/.test(contactForm.mobileNumber.replace(/\D/g, ""))) {
      errors.mobileNumber = "Please enter a valid 10-digit mobile number";
    }

    setContactErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validate enquiry form
  const validateEnquiryForm = () => {
    const errors = {};

    if (!enquiryForm.name.trim()) {
      errors.name = "Name is required";
    }

    if (!enquiryForm.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(enquiryForm.email)) {
      errors.email = "Email is invalid";
    }

    if (!enquiryForm.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(enquiryForm.phone.replace(/\D/g, ""))) {
      errors.phone = "Please enter a valid 10-digit phone number";
    }

    setEnquiryErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Get formatted date and time
  const getFormattedDateTime = () => {
    const now = new Date();

    // Format date as dd/mm/yyyy
    const dd = String(now.getDate()).padStart(2, "0");
    const mm = String(now.getMonth() + 1).padStart(2, "0");
    const yyyy = now.getFullYear();
    const formattedDate = `${dd}/${mm}/${yyyy}`;

    // Format time as hh:mm:ss
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return { date: formattedDate, time: formattedTime };
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
    return false;
  };

  // 2. Function to only allow numbers in phone fields
  const onlyAllowNumbers = (e) => {
    // Allow: backspace, delete, tab, escape, enter, and navigation
    if (
      [8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
      ((e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 86 ||
        e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // Let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress if not
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  // Submit contact form
  // Updated handleContactSubmit function
  const handleContactSubmit = async (e) => {
    e.preventDefault();

    if (!validateContactForm()) {
      return;
    }

    setContactLoading(true);

    try {
      const { date, time } = getFormattedDateTime();

      // 1. First submit to Google Script (your existing sheet)
      const params = new URLSearchParams();
      params.append("name", contactForm.fullName);
      params.append("email", contactForm.email);
      params.append("mobile", contactForm.mobileNumber);
      params.append(
        "message",
        contactForm.message || "Contact form submission"
      );
      params.append("date", date);
      params.append("time", time);

      // Append UTM parameters
      Object.keys(utmParams).forEach((key) => {
        params.append(key, utmParams[key]);
      });

      await fetch(
        "https://script.google.com/macros/s/AKfycbygn3mIcpH0ITCMRrBpEmorh5tfE3LuswM9me9d3m5CyJHlmytgbjy0ri9_ekV-3gB6cw/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params.toString(),
        }
      );

      // 2. Submit to LeadSquared CRM following the exact format from documentation
      const nameParts = contactForm.fullName.split(" ");
      const firstName = nameParts[0] || "";
      const lastName =
        nameParts.length > 1 ? nameParts.slice(1).join(" ") : ".";

      const crmPayload = {
        LeadDetails: [
          {
            Attribute: "Phone",
            Value: contactForm.mobileNumber,
          },
          {
            Attribute: "FirstName",
            Value: firstName,
          },
          {
            Attribute: "LastName",
            Value: lastName,
          },
          {
            Attribute: "EmailAddress",
            Value: contactForm.email,
          },
          {
            Attribute: "mx_City",
            Value: "Bangalore",
          },
          {
            Attribute: "SearchBy",
            Value: "Phone",
          },
        ],
        Opportunity: {
          OpportunityEventCode: 12000,
          Fields: [
            {
              SchemaName: "mx_Custom_1", // Opportunity Name
              Value: `${contactForm.fullName} - Surya Valencia`,
            },
            {
              SchemaName: "mx_Custom_16", // Project Name
              Value: "Surya Valencia",
            },
            {
              SchemaName: "mx_Custom_17", // Project Type
              Value: "Residential",
            },
            {
              SchemaName: "mx_Custom_64", // Configuration Interested in
              Value: "4 BHK", // Using the appropriate value from the dropdown list
            },
            {
              SchemaName: "Owner",
              Value: "digital.integration@xanadu.in",
            },
            {
              SchemaName: "mx_Custom_73",
              Value: "Web Leads",
            },
            {
              SchemaName: "Status",
              Value: "Open",
            },
            {
              SchemaName: "mx_Custom_2", // Stage
              Value: "Not Attempted",
            },
            {
              SchemaName: "mx_Custom_3", // Source
              Value: utmParams.utm_source || "NA",
            },
            {
              SchemaName: "mx_Custom_20", // Campaign
              Value: utmParams.utm_campaign || "NA",
            },
            {
              SchemaName: "mx_Custom_21", // Medium
              Value: utmParams.utm_medium || "NA",
            },
          ],
        },
      };

      // Submit to LeadSquared CRM - using the exact endpoint from the documentation
      const crmResponse = await fetch(
        "https://api-in21.leadsquared.com/v2/OpportunityManagement.svc/Capture?accessKey=u$r7b3a5c5c20947d02ad372f707c93a0b6&secretKey=8d135be8ffe8346e335fe50ef899a55c9618ce9b",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(crmPayload),
        }
      );

      // For debugging
      console.log("CRM Response:", await crmResponse.json());

      // Reset form after successful submission
      setContactForm({
        fullName: "",
        email: "",
        mobileNumber: "",
        message: "",
      });

      // Redirect to thank you page
      navigate("/form-thank-you");
    } catch (error) {
      console.error("Error submitting form:", error);
      setContactErrors({
        ...contactErrors,
        submit: "Failed to submit form. Please try again.",
      });
    } finally {
      setContactLoading(false);
    }
  };

  // Updated handleEnquirySubmit function
  const handleEnquirySubmit = async (e) => {
    e.preventDefault();

    if (!validateEnquiryForm()) {
      return;
    }

    setEnquiryLoading(true);

    try {
      const { date, time } = getFormattedDateTime();

      // 1. First submit to Google Script (your existing sheet)
      const params = new URLSearchParams();
      params.append("name", enquiryForm.name);
      params.append("email", enquiryForm.email);
      params.append("mobile", enquiryForm.phone);
      params.append(
        "message",
        enquiryForm.message || "Enquiry from modal form"
      );
      params.append("date", date);
      params.append("time", time);

      // Append UTM parameters
      Object.keys(utmParams).forEach((key) => {
        params.append(key, utmParams[key]);
      });

      await fetch(
        "https://script.google.com/macros/s/AKfycbygn3mIcpH0ITCMRrBpEmorh5tfE3LuswM9me9d3m5CyJHlmytgbjy0ri9_ekV-3gB6cw/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params.toString(),
        }
      );

      // 2. Submit to LeadSquared CRM following the exact format from documentation
      const nameParts = enquiryForm.name.split(" ");
      const firstName = nameParts[0] || "";
      const lastName =
        nameParts.length > 1 ? nameParts.slice(1).join(" ") : ".";

      const crmPayload = {
        LeadDetails: [
          {
            Attribute: "Phone",
            Value: enquiryForm.phone,
          },
          {
            Attribute: "FirstName",
            Value: firstName,
          },
          {
            Attribute: "LastName",
            Value: lastName,
          },
          {
            Attribute: "EmailAddress",
            Value: enquiryForm.email,
          },
          {
            Attribute: "mx_City",
            Value: "Bangalore",
          },
          {
            Attribute: "SearchBy",
            Value: "Phone",
          },
        ],
        Opportunity: {
          OpportunityEventCode: 12000,
          Fields: [
            {
              SchemaName: "mx_Custom_1", // Opportunity Name
              Value: `${firstName} - Surya Valencia`,
            },
            {
              SchemaName: "mx_Custom_16", // Project Name
              Value: "Surya Valencia",
            },
            {
              SchemaName: "mx_Custom_17", // Project Type
              Value: "Residential",
            },
            {
              SchemaName: "mx_Custom_64", // Configuration Interested in
              Value: "4 BHK", // Using the appropriate value from the dropdown list
            },
            {
              SchemaName: "Owner",
              Value: "digital.integration@xanadu.in",
            },
            {
              SchemaName: "mx_Custom_73",
              Value: "Web Leads",
            },
            {
              SchemaName: "Status",
              Value: "Open",
            },
            {
              SchemaName: "mx_Custom_2", // Stage
              Value: "Not Attempted",
            },
            {
              SchemaName: "mx_Custom_3", // Source
              Value: utmParams.utm_source || "NA",
            },
            {
              SchemaName: "mx_Custom_20", // Campaign
              Value: utmParams.utm_campaign || "NA",
            },
            {
              SchemaName: "mx_Custom_21", // Medium
              Value: utmParams.utm_medium || "NA",
            },
          ],
        },
      };

      console.log("Enquire Payload:", crmPayload);

      // Submit to LeadSquared CRM - using the exact endpoint from the documentation
      const crmResponse = await fetch(
        "https://api-in21.leadsquared.com/v2/OpportunityManagement.svc/Capture?accessKey=u$r7b3a5c5c20947d02ad372f707c93a0b6&secretKey=8d135be8ffe8346e335fe50ef899a55c9618ce9b",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(crmPayload),
        }
      );

      // For debugging
      // console.log("CRM Response:", await crmResponse.json());

      // Reset form after successful submission
      setEnquiryForm({
        name: "",
        email: "",
        phone: "",
        message: "Enquiry from modal form",
      });

      // Close modal and redirect to thank you page
      closeModal();
      navigate("/form-thank-you");
    } catch (error) {
      console.error("Error submitting form:", error);
      setEnquiryErrors({
        ...enquiryErrors,
        submit: "Failed to submit form. Please try again.",
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  return (
    <div>
      <Navbar></Navbar>

      <section
        id="bannerSection"
        className="p-0 position-relative homeBanner one-fourth-screen wow animate__fadeIn bg-white"
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 p-0 d-flex flex-column justify-content-center text-center md-landscape-h-600px">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src="img/surya-desktop-banner-updated.png" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section id="overview" className="pt-5 pb-0">
        <div className="container">
          <div className="row justify-content-center d-flex align-items-center">
            <div
              className="col-12 col-md-6 wow animate__fadeIn"
              data-wow-delay="0.4s"
            >
              <h5 className="font-weight-600 letter-spacing-1px text-fast-blue mb-5">
                OVERVIEW OF PROJECT
              </h5>

              {/* <ul className="list-style-06">
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.2s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <p className="w-80 lg-w-90 xs-w-100">
                      Surya Valencia is a premium villa project located in Rajankunte, Bangalore, offering spacious 4BHK and 5BHK villas designed for luxury
                      living.
                    </p>
                  </div>
                </li>
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.4s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <p className="w-80 lg-w-90 xs-w-100">
                      {" "}
                      The development features over 40 high-end amenities, including a clubhouse, fitness center, swimming pool, sports courts, wellness center,
                      and beautifully landscaped gardens, ensuring a comprehensive and elevated lifestyle.
                    </p>
                  </div>
                </li>
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.4s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <p className="w-80 lg-w-90 xs-w-100">
                      With its serene and well-connected location, Surya Valencia caters to those seeking exclusivity and privacy, offering modern design,
                      eco-friendly features, and unparalleled comfort.
                    </p>
                  </div>
                </li>
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.4s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <p className="w-80 lg-w-90 xs-w-100">
                      The project is positioned in the top tier of Bangalore's luxury villa segment, appealing to those desiring a sophisticated living
                      experience.
                    </p>
                  </div>
                </li>
              </ul> */}

              <p>
                Surya Valencia is a premium villa project located in Rajankunte,
                Bangalore, offering spacious 4BHK and 5BHK villas designed for
                luxury living. The development features over 40 high-end
                amenities, including a clubhouse, fitness center, swimming pool,
                sports courts, wellness center, and beautifully landscaped
                gardens, ensuring a comprehensive and elevated lifestyle. With
                its serene and well-connected location, Surya Valencia caters to
                those seeking exclusivity and privacy, offering modern design,
                eco-friendly features, and unparalleled comfort. The project is
                positioned in the top tier of Bangalore's luxury villa segment,
                appealing to those desiring a sophisticated living experience.
              </p>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-12 mt-3">
                  {" "}
                  <ul className="blog-masonry blog-wrapper grid grid-1col">
                    <li className="grid-sizer"></li>

                    <li className="grid-item wow animate__fadeIn">
                      <div className="blog-post text-center">
                        <div className="blog-post-image">
                          <img src="img/image4.jpg" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="landmark" className="half-section wow animate__fadeIn">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5 className="mb-3 mb-md-5 letter-spacing-1px font-weight-600 text-fast-blue">
                LANDMARKS BY CATEGORIES:
              </h5>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="text-black text-extra-large font-weight-600">
                    School/College
                  </h6>
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Presidency University - 10 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Vidyasilip University - 20 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Stonehill International School - 20 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Nitte Meenakshi Institute of technology
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Harrow International School - 30 mins
                      </span>
                    </li>
                  </ul>

                  <h6 className="text-black text-extra-large font-weight-600">
                    Hospitals
                  </h6>
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Manipal hospital North - 30 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Cytecare Hospitals - 30 mins
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h6 className="text-black text-extra-large font-weight-600">
                    Transportation Connectivity
                  </h6>{" "}
                  <ul className="list-style-02">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Kempegowda International Airport - 30 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>

                      <span className="text-black">
                        {" "}
                        Yelahanka Railway Station
                      </span>
                    </li>
                  </ul>
                  <h6 className="text-black text-extra-large font-weight-600">
                    Commercial
                  </h6>
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Prestige Tech Cloud - 20 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Brigade magnum - 30 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        {" "}
                        L&T tech Park- 30 mins
                      </span>
                    </li>
                  </ul>
                  <h6 className="text-black text-extra-large font-weight-600">
                    Sports
                  </h6>
                  <ul className="list-style-02">
                    <li className="margin-20px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        {" "}
                        Padukone Dravid Center for Excellence - 20 mins
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-12">
                  <h6 className="text-black text-extra-large font-weight-600">
                    Lesiure & Entrtainment
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Spree Resort - 10 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Silver Oak Resort - 10 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Park Inn & Suites by Radisson - 10 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Angsana Oasis Spa & Resort - 10 mins
                      </span>
                    </li>

                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Ramanashree California Resort - 20 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Dome Retreats - 10 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Moxy Bengaluru Airport - 20 mins
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Rico Wine Park & Vineyards - 20 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Terraform Arena - 20 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Club Cabana - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Royal Orchid Resorts - 30 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Hesaraghatta Grasslands Hiking - 30 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Dmart - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">
                        Phoenix Mall of Asia - 30 mins
                      </span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Bloom Hotel - 30 mins </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-6 col-lg-5 order-md-2 mb-3 mb-lg-0">
              <img className="img-fluid" src="img/SuryalocationMP.jpg" />
            </div>
            <div className="col-md-6  col-lg-7 order-md-1">
              <div className="d-flex flex-column gap-5 landmarksAccordion">
                {categories.map((category, index) => (
                  <div
                    key={index}
                    className="border rounded-lg overflow-hidden"
                  >
                    <button
                      onClick={() => toggleAccordion(index)}
                      className="w-100 text-left bg-white box-shadow-small ox-4 p-3 border-0 font-semibold text-black d-flex justify-content-between"
                    >
                      {category.title}

                      <i class="indicator fas fa-angle-down text-extra-dark-gray icon-extra-small"></i>
                    </button>
                    {openIndex === index && (
                      <ul className="p-4 px-md-5 bg-white border-top border-[#dbdbdb] list-unstyled">
                        {category.items.map((item, i) => (
                          <li key={i} className="mb-2 flex items-center">
                            <i className="feather icon-feather-arrow-right-circle text-fast-blue mr-2"></i>
                            <span className="text-black">{item}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="half-section wow animate__fadeIn bg-fast-blue">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h5 className="font-weight-600 text-white mb-5 letter-spacing-1px">
                USP
              </h5>
            </div>
          </div>
          <div className="row justify-content-center d-flex">
            <div className="col-md-4">
              <ul className="list-style-01 text-white">
                <li>
                  <i className="fas fa-check text-white"></i>Provision for lift
                  in every villa
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>Backyard for every
                  villa
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>20,000 sq. ft.
                  upcoming clubhouse
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-style-01 text-white">
                <li>
                  <i className="fas fa-check text-white"></i>Open Backyard
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>Large Terraces (not
                  1 but 2)
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>

                <li>
                  <i className="fas fa-check text-white"></i>Spanish villa
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-style-01 text-white">
                <li>
                  <i className="fas fa-check text-white"></i>Upcoming Location
                  with leisure lifestyle and promise of future growth
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>All bedrooms with
                  balconies and walk-in wardrobes
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="half-section " id="amenities">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h5 className="font-weight-600 text-fast-blue mb-5 letter-spacing-1px">
                AMENITIES
              </h5>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div
                className="panel-group accordion-event accordion-style-03"
                id="accordion3"
                data-active-icon="fa-angle-down"
                data-inactive-icon="fa-angle-right"
              >
                <div
                  className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                  data-wow-delay="0.2s"
                >
                  <div className="panel-heading active-accordion">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-01"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">
                          KIDS:
                        </span>
                        <i className="indicator fas fa-angle-down text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    id="accordion-style-03-01"
                    className="panel-collapse collapse show"
                    data-parent="#accordion3"
                  >
                    <div className="panel-body">
                      <div className="row row-cols-2 row-cols-md-6  justify-content-center">
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/7.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Kids Play Area
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/11.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Kids Pool
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/18.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Play Lawn
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/colonnade-Walk.png"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Colonnade Walks
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/36.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Maze Walk
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/2.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Multipurpose Hall
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                  data-wow-delay="0.4s"
                >
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-02"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">
                          CLUBHOUSE:
                        </span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    id="accordion-style-03-02"
                    className="panel-collapse collapse"
                    data-parent="#accordion3"
                  >
                    <div className="panel-body">
                      <div className="row row-cols-2 row-cols-md-6  justify-content-center">
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/19.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Table Tennis Room
                          </span>
                        </div>
                        {/* <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/19.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom" style={{maxWidth:"5rem"}} alt=''
                          />
                          <span className="text-fast-blue d-block">
                            Billiards room
                          </span>
                        </div>*/}
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/14.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Toilet & Change rooms
                          </span>
                        </div>

                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/24.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            AV Room
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/21.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Indoor Games
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/32.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Multipurpose Court
                          </span>
                        </div>
                        {/* <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/35.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom" style={{maxWidth:"5rem"}} alt=''
                          />
                          <span className="text-fast-blue d-block">
                            Yoga/Activity Area
                          </span>
                        </div>

                        
                        
                        
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/40.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom" style={{maxWidth:"5rem"}} alt=''
                          />
                          <span className="text-fast-blue d-block">
                            Colanade Walk
                          </span>
                        </div>
                        
                         */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-03"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">
                          FITNESS / FRESHNESS:
                        </span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    id="accordion-style-03-03"
                    className="panel-collapse collapse"
                    data-parent="#accordion3"
                  >
                    <div className="panel-body">
                      <div className="row row-cols-2 row-cols-md-6  justify-content-center">
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/6.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">Creche</span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/15.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Gymnasium
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/17.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Yoga Room
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/10.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Swimming Pool
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/13.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Paddle Pool
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/22.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Badminton Court
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/16.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Outdoor Gym
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/Mini-putting-area.png"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Mini putting area
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-04"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">
                          SENIOR CITIZEN:
                        </span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    id="accordion-style-03-04"
                    className="panel-collapse collapse"
                    data-parent="#accordion3"
                  >
                    <div className="panel-body">
                      <div className="row row-cols-2 row-cols-md-6  justify-content-center">
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/37.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Senor seating alcove
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/37.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Aroma Walk
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/33.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Butterfly Garden
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/34.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Meditation Garden
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/44.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Trellis with Garden Sculpture
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/39.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Focal Trellis
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/39.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Floral fountain
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/38.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Congregation Lawn
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-05"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">
                          ENTERTAINTMENT:
                        </span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    id="accordion-style-03-05"
                    className="panel-collapse collapse"
                    data-parent="#accordion3"
                  >
                    <div className="panel-body">
                      <div className="row row-cols-2 row-cols-md-6  justify-content-center">
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/1.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Double Height Lobby
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/3.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">Pantry</span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/3.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">Lounge</span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/23.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Salon & Spa
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/9.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Pool Deck
                          </span>
                        </div>

                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/12.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Jacuzzi
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/29.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Party Terrace
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/30.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Barbeque Counter
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/42.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Amphitheatre
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/43.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Open Party Area
                          </span>
                        </div>

                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/Billiards-room.png"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Snooker/Billiards Room
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-06"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">
                          WORKING:
                        </span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    id="accordion-style-03-06"
                    className="panel-collapse collapse"
                    data-parent="#accordion3"
                  >
                    <div className="panel-body">
                      <div className="row row-cols-2 row-cols-md-6  justify-content-center">
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/4.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Co-working Space
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/28.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Conference Room
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/31.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Guest Suites
                          </span>
                        </div>
                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/8.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Convenience Store
                          </span>
                        </div>

                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/5.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Cafeteria
                          </span>
                        </div>

                        <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/26.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom"
                            style={{ maxWidth: "5rem" }}
                            alt=""
                          />
                          <span className="text-fast-blue d-block">
                            Library / Reading room
                          </span>
                        </div>

                        {/* <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/21.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom" style={{maxWidth:"5rem"}} alt=''
                          />
                          <span className="text-fast-blue d-block">
                            Indoor Games
                          </span>
                        </div> */}
                        {/* <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/25.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom" style={{maxWidth:"5rem"}} alt=''
                          />
                          <span className="text-fast-blue d-block">
                            Spa & Sauna
                          </span>
                        </div> */}
                        {/* <div
                          className="col text-center margin-20px-bottom margin-50px-md-bottom wow animate__fadeIn"
                          data-wow-delay="0.1s"
                        >
                          <img
                            src="img/27.jpg"
                            className="imgx padding-0px-bottom padding-20px-md-bottom" style={{maxWidth:"5rem"}} alt=''
                          />
                          <span className="text-fast-blue d-block">
                            Golf Putting Zone
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="half-section pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-12 text-center wow animate__fadeIn"
              data-wow-delay="0.1s"
            >
              <h5 className="mb-5 font-weight-600 text-fast-blue letter-spacing-1px ">
                GALLERY
              </h5>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 p-0 wow animate__fadeIn text-center"
              data-wow-delay="0.4s"
            >
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src="img/g1.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="img/g2.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="img/g3.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="img/g4.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section
        id="floor"
        className="half-section bg-light-gray wow animate__fadeIn"
        data-wow-delay="0.4s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-12 text-center wow animate__fadeIn"
              data-wow-delay="0.1s"
            >
              <h5 className="mb-5 font-weight-600 text-fast-blue letter-spacing-1px ">
                FLOOR PLAN
              </h5>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 margin-30px-bottom xs-margin-15px-bottom">
              <div className="floorPlanImg" onClick={openModal}>
                <img src="img/4bh.jpg" className="mb-4 floorPlanImg" />
              </div>
              <h5 className="mb-2 mb-md-5 alt-font text-fast-blue font-weight-600 letter-spacing-minus-1px">
                4 BHK
              </h5>
              <ul className="list-style-02">
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    Saleable Area - 3120 sqft. - 3343 sqft.
                  </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">2 types of villas </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    West Facing Plot - North Facing Villa{" "}
                  </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    East Facing Plot - East Facing Villa.
                  </span>
                </li>
              </ul>
            </div>

            <div className="col-md-6 margin-30px-bottom xs-margin-15px-bottom">
              <div className="floorPlanImg" onClick={openModal}>
                <img src="img/5bh.jpg" className="mb-4 floorPlanImg" />
              </div>
              <h5 className="alt-font mb-2 mb-md-5 text-fast-blue font-weight-600 letter-spacing-minus-1px">
                5 BHK
              </h5>

              <ul className="list-style-02">
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    Saleable Area - 3869 sqft. - 3960 sqft.{" "}
                  </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">3 types of villas </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    West Facing Plot - North Facing Villa{" "}
                  </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    East Facing Plot - East Facing Villa
                  </span>
                </li>
                <li className="margin-10px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    North Facing Plot - North Facing Villa.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="half-section bg-neon-blue">
        <div className="container">
          <div className="row justify-content-center d-flex align-items-center">
            <div
              className="col-12 col-md-6 wow animate__fadeIn"
              data-wow-delay="0.4s"
            >
              <h5 className="mb-3 mb-md-5 letter-spacing-1px font-weight-600 text-fast-blue">
                WHY CHOOSING <br />
                VILLA OVER APARTMENT
              </h5>
              {/* <ul className="list-style-02">
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    Let's collaborate make an impact in the business
                  </span>
                </li>
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    A satisfied customer is the best business starts
                  </span>
                </li>
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    Expertise to build the things that matter for you
                  </span>
                </li>
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    Expertise to build the things that matter for you
                  </span>
                </li>
              </ul> */}
              <ul className="list-style-02">
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    Experience True Freedom with every bedroom featuring a
                    private balcony and a spacious walk-in wardrobe adding
                    luxury to your living
                  </span>
                </li>
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    Escape the Apartment, Choose Surya Valencia with Spanish
                    touch for a Peaceful & Tranquil Lifestyle
                  </span>
                </li>
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    Secure Your Future, investing in a Spanish villa near
                    Yelahanka, for Long-Term Value and Smart Pricing.
                  </span>
                </li>
                <li className="margin-20px-bottom">
                  <i
                    className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                    aria-hidden="true"
                  ></i>
                  <span className="text-black">
                    {" "}
                    Elevate your lifestyle by enjoying exclusive amenities and
                    two terraces to call your own in a villa over an apartment.
                  </span>
                </li>
              </ul>
              <p className="text-black">
                Surya Developers is a company founded on trust and expertise,
                with over 20 years of experience in real estate development and
                project administration. Our team of professionals is dedicated
                to delivering exceptional service and ensuring timely completion
                of every project.
              </p>
            </div>

            <div
              className="col-12 col-md-6 wow animate__fadeIn"
              data-wow-delay="0.4s"
            >
              <img className="w-100" src="img/s1.png" />
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container">
          <div className="row justify-content-center d-flex align-items-center">
            <div className="col-md-6">
              <h5 className="text-fast-blue font-weight-600">CONTACT US</h5>
              <div className="mb-5 border-all border-color-medium-gray p-5">
                <form onSubmit={handleContactSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="fullName"
                      value={contactForm.fullName}
                      onChange={handleContactChange}
                      onCopy={preventCopyPaste}
                      onPaste={preventCopyPaste}
                      onCut={preventCopyPaste}
                      className={`medium-input margin-25px-bottom xs-margin-10px-bottom ${
                        contactErrors.fullName ? "border-color-red" : ""
                      }`}
                      placeholder="Full Name"
                    />
                    {contactErrors.fullName && (
                      <p className="text-red text-small">
                        {contactErrors.fullName}
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      value={contactForm.email}
                      onChange={handleContactChange}
                      onCopy={preventCopyPaste}
                      onPaste={preventCopyPaste}
                      onCut={preventCopyPaste}
                      className={`medium-input margin-25px-bottom xs-margin-10px-bottom ${
                        contactErrors.email ? "border-color-red" : ""
                      }`}
                      placeholder="Email"
                    />
                    {contactErrors.email && (
                      <p className="text-red text-small">
                        {contactErrors.email}
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="mobileNumber"
                      value={contactForm.mobileNumber}
                      onChange={handleContactChange}
                      onCopy={preventCopyPaste}
                      onPaste={preventCopyPaste}
                      onCut={preventCopyPaste}
                      onKeyDown={onlyAllowNumbers}
                      className={`medium-input margin-25px-bottom xs-margin-10px-bottom ${
                        contactErrors.mobileNumber ? "border-color-red" : ""
                      }`}
                      placeholder="Mobile Number"
                    />
                    {contactErrors.mobileNumber && (
                      <p className="text-red text-small">
                        {contactErrors.mobileNumber}
                      </p>
                    )}
                  </div>

                  <div className="form-group" style={{ display: "none" }}>
                    <input
                      type="text"
                      name="message"
                      value={contactForm.message}
                      onChange={handleContactChange}
                      onCopy={preventCopyPaste}
                      onPaste={preventCopyPaste}
                      onCut={preventCopyPaste}
                      className="medium-input margin-25px-bottom xs-margin-10px-bottom"
                    />
                  </div>

                  {contactErrors.submit && (
                    <p className="text-red text-small margin-15px-bottom">
                      {contactErrors.submit}
                    </p>
                  )}

                  {contactSuccess && (
                    <div className="alert alert-success margin-15px-bottom">
                      Your message has been sent successfully!
                    </div>
                  )}

                  <button
                    type="submit"
                    className="btn btn-medium btn-fast-blue mb-0"
                    disabled={contactLoading}
                  >
                    {contactLoading ? (
                      <span>
                        <i className="fa fa-spinner fa-spin mr-2"></i>{" "}
                        Submitting...
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
              <p className="text-black">
                No.684,2nd floor, HIG 'A' Sector,
                <br />
                Opposite to Sheshadripuram College,
                <br />
                Above State Bank of Hyderabad,
                <br /> Yelahanka NewTown, Bangalore - 560064.
              </p>
              <p className="text-black">E-Mail : info@suryadevelopers.in</p>
            </div>
            <div className="col-md-6">
              <img src="img/location.svg" />
            </div>
          </div>
        </div>
      </section>

      <div id="disclaimer">
        <div className="container">
          <div className="disclaimerContent">
            <h3>Disclaimer:</h3>
            <p>
              The information provided on this website is for general purposes
              only. Prices are subject to change without prior notice, and
              property availability is not guaranteed. All images are for
              illustrative purposes and may not reflect the actual product or
              property. By using this website, you consent to receiving updates
              and notifications to the mobile number and/or email address you
              have registered with us.
            </p>
          </div>
        </div>
      </div>

      <footer className="footer-dark bg-black">
        <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
          <div className="container">
            <div className="row align-items-center justify-content-center d-flex">
              <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  <img src="img/logo.png" alt="" />
                </a>
              </div>
              <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  <img src="img/footer-logo.svg" alt="" />
                </a>
              </div>

              <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-small">
                  {" "}
                  <a classname="text-white" href="#">
                    PRIVACY POLICY
                  </a>{" "}
                </p>
              </div>
              <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-small">
                  {" "}
                  <a classname="text-white" href="#">
                    TC's
                  </a>{" "}
                </p>
              </div>
              <div className="col-12 col-md-2 text-center sm-margin-20px-bottom">
                <p className="text-white font-weight-600 text-small">
                  Copyright2025Surya
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div>
        <button onClick={openModal} className=" floating-button">
          ENQUIRE
        </button>

        <Modal isOpen={isModalOpen} closeModal={closeModal}>
          <div className="padding-ten-all bg-white border-radius-6px xs-padding-six-all">
            <h6 className="text-extra-dark-gray text-center font-weight-500 margin-35px-bottom xs-margin-15px-bottom">
              ENQUIRE NOW
            </h6>
            <form onSubmit={handleEnquirySubmit}>
              <div className="form-group">
                <input
                  className={`medium-input margin-15px-bottom xs-margin-10px-bottom required ${
                    enquiryErrors.name ? "border-color-red" : ""
                  }`}
                  type="text"
                  name="name"
                  value={enquiryForm.name}
                  onChange={handleEnquiryChange}
                  onCopy={preventCopyPaste}
                  onPaste={preventCopyPaste}
                  onCut={preventCopyPaste}
                  placeholder="Your name"
                />
                {enquiryErrors.name && (
                  <p className="text-red text-small">{enquiryErrors.name}</p>
                )}
              </div>

              <div className="form-group">
                <input
                  className={`medium-input margin-15px-bottom xs-margin-10px-bottom required ${
                    enquiryErrors.email ? "border-color-red" : ""
                  }`}
                  type="email"
                  name="email"
                  value={enquiryForm.email}
                  onChange={handleEnquiryChange}
                  onCopy={preventCopyPaste}
                  onPaste={preventCopyPaste}
                  onCut={preventCopyPaste}
                  placeholder="Your email address"
                />
                {enquiryErrors.email && (
                  <p className="text-red text-small">{enquiryErrors.email}</p>
                )}
              </div>

              <div className="form-group">
                <input
                  className={`medium-input margin-15px-bottom xs-margin-10px-bottom ${
                    enquiryErrors.phone ? "border-color-red" : ""
                  }`}
                  type="tel"
                  name="phone"
                  value={enquiryForm.phone}
                  onChange={handleEnquiryChange}
                  onCopy={preventCopyPaste}
                  onPaste={preventCopyPaste}
                  onCut={preventCopyPaste}
                  onKeyDown={onlyAllowNumbers}
                  placeholder="Your mobile"
                />
                {enquiryErrors.phone && (
                  <p className="text-red text-small">{enquiryErrors.phone}</p>
                )}
              </div>

              <div className="form-group" style={{ display: "none" }}>
                <input
                  type="text"
                  name="message"
                  value={enquiryForm.message}
                  onChange={handleEnquiryChange}
                  className="medium-input"
                />
              </div>

              {enquiryErrors.submit && (
                <p className="text-red text-small margin-15px-bottom">
                  {enquiryErrors.submit}
                </p>
              )}

              {enquirySuccess && (
                <div className="alert alert-success margin-15px-bottom">
                  Your enquiry has been sent successfully!
                </div>
              )}

              <div className="text-center">
                <button
                  className="btn btn-medium btn-fast-blue mb-0 submit"
                  type="submit"
                  onClick={handleEnquirySubmit}
                  disabled={enquiryLoading}
                >
                  {enquiryLoading ? (
                    <span>
                      <i className="fa fa-spinner fa-spin mr-2"></i>{" "}
                      Submitting...
                    </span>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default App;

import React from 'react'

function Navbar() {
  return (
    <div>   <header>
    <nav className="navbar navbar-expand-lg navbar-boxed navbar-dark bg-fast-blue fixed-top navbar-boxed navbar-static-overlay">
        <div className="container-fluid h-100px md-h-70px">
             <div className="col-2 text-left">
                
                <div className="header-push-button">
                    <a href="javascript:void(0);" className="push-button">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
            </div>
            <div className="text-center col-9 mr-auto mr-auto pl-lg-0">
                <a className="navbar-brand" href="index.html">
                    <img src="img/logo.png" data-at2x="img/logo.png" className="default-logo" alt=""/>
                    <img src="img/logo.png" data-at2x="img/logo.png" className="alt-logo" alt=""/>
                    <img src="img/logo.png" data-at2x="img/logo.png" className="mobile-logo" alt=""/>
                </a>
            </div>
           
        </div>
    </nav>
   
    <div className="hamburger-menu hamburger-menu-half bg-extra-dark-gray lg-w-60 md-w-60 sm-w-100">
        <a href="javascript:void(0);" className="close-menu text-white"><i className="fas fa-times"></i></a>
        <div className="d-flex flex-column align-items-center justify-content-center h-100 padding-6-rem-all md-padding-2-rem-lr xs-no-padding-lr">
            <a href="index.html" className="d-none d-lg-inline-block"><img src="img/logo.png" alt="" className="md-w-160px" /></a>
            <div className="col-12 col-xl-9 col-lg-8 col-md-9 col-sm-7 menu-list-wrapper margin-3-rem-tb" data-scroll-options='{ "theme": "light" }'>
               
                <ul className="menu-list alt-font w-70 xl-w-100 margin-auto-lr">
                   
                    <li className="menu-list-item"><a href="/">Home</a></li>
                     <li className="menu-list-item"><a href="clubplan">Club Plan</a></li>
                      <li className="menu-list-item"><a href="floorplan">Floor Plan</a></li>
                      <li className="menu-list-item"><a href="masterplan">Master Plan</a></li>
                       
                  
                </ul>
               
            </div>
            <div className="text-center elements-social social-icon-style-12 d-none d-md-inline-block">
                <ul className="small-icon light">
                    <li><a className="facebook" href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a className="dribbble" href="http://www.dribbble.com" target="_blank"><i className="fab fa-dribbble"></i></a></li>
                    <li><a className="twitter" href="http://www.twitter.com" target="_blank"><i className="fab fa-twitter"></i></a></li>
                    <li><a className="instagram" href="http://www.instagram.com" target="_blank"><i className="fab fa-instagram"></i></a></li>
                    <li><a className="linkedin" href="http://www.linkedin.com" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
   
</header>
</div>
  )
}

export default Navbar
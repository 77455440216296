import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import reportWebVitals from "./reportWebVitals";
import ClubPlan from "./pages/ClubPlan";
import FloorPlan from "./pages/FloorPlan";
import MasterPlan from "./pages/MasterPlan";
import Demo from "./pages/Demo";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SalesLeadForm from "./pages/SalesLeadForm";
import FormThankYou from "./pages/FormThankYou";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "clubplan",
    element: <ClubPlan />,
  },
  {
    path: "floorplan",
    element: <FloorPlan />,
  },
  {
    path: "masterplan",
    element: <MasterPlan />,
  },

  {
    path: "demo",
    element: <Demo />,
  },
  {
    path: "form-thank-you",
    element: <FormThankYou />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import Navbar from "../components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

function FloorPlan() {
  return (
    <div>
      <Navbar></Navbar>
      <section className="half-section bg-white wow animate__fadeIn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 wow animate__fadeIn" data-wow-delay="0.4s">
              <h5 class="mt-5 font-weight-600 text-fast-blue">Floor Plan</h5>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src="img/fp.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="img/fp.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="img/fp.png" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-dark bg-black">
        <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-1"></div>
              <div className="col-12 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-white text-small">
                  A flagship project by Surya Developers
                </p>
                <a href="index.html" className="footer-logo">
                  <img src="img/logo.png" data-at2x="img/logo.png" alt="" />
                </a>
              </div>
              <div className="col-6 col-md-3 last-paragraph-no-margin sm-margin-20px-bottom">
                <p className="text-white text-small font-weight-600">
                  HEAD OFFICE
                </p>
                <p className="text-white text-small">
                  # 684, 2nd Floor, HIG ‘A’ Sector, Opposite to Sheshadripuram
                  College, Yelahanka New Town, Bengaluru - 560 064
                </p>
              </div>
              <div className="col-6 col-md-3 last-paragraph-no-margin sm-margin-20px-bottom">
                <p className="text-white font-weight-600 text-small">
                  GET IN TOUCH
                </p>
                <p className="text-white text-small">
                  {" "}
                  E-Mail:{" "}
                  <a href="mailto:info@suryadevelopers.in">
                    info@suryadevelopers.in
                  </a>{" "}
                  <br />
                  Mob: <a href="tel:917411118484">+91 74 1111 8484</a> <br />
                  Ph no: <a href="tel:918041228484">+91 80 4122 8484</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FloorPlan;

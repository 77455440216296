import React from 'react'
import { useState } from "react";
import Axios from 'axios'
const Pform = () => {
    const [openPopup, setOpenPopup] = useState(false)
    const [tokenId, setTokenId] = useState('');
    const [leadFirstName, setLeadFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [enquirySourceId, setEnquirySourceId] = useState('1050');
    const [projectName, setProjectName] = useState('SuryaValencia Project');
    const [subSource, setSubSource] = useState('Housing.com');
    const [mediumCampaign, setMediumCampaign] = useState('Banner');
    const [keywords, setKeywords] = useState('2 BHK, 3 BHK');
    const [probability, setProbability] = useState('10');
    const [category, setCategory] = useState('1');
    const [type, setType] = useState('1');
    const [subProjectName, setSubProjectName] = useState('Surya Valencia');
    const [tokenStatus, setTokenStatus] = useState('');
    const [leadStatus, setLeadStatus] = useState('');

    const getSecurityToken = async () => {
        const serviceUrl =
            'https://suryadevelopers.in4suite.com/SalesCustomerAPI/api/Lead/GetSecurityToken' +
            `?apiUser=surya&apiKey=surya@In4`;

        try {
            const response = await fetch(serviceUrl);
            const data = await response.text();
            setTokenStatus(data);

            const jsonOutput = JSON.parse(data);
            if (jsonOutput.tokenId) {
                setTokenId(jsonOutput.tokenId);
            }
        } catch (error) {
            setTokenStatus('Error: ' + error.message);
        }
    };

    const submitSalesLead = async () => {
        if (!tokenId) {
            alert('Please generate the token before creating the sales lead.');
            return;
        }

        const serviceUrl =
            'https://suryadevelopers.in4suite.com/SalesCustomerAPI/api/Lead/CreateSalesLead';

        const jsonParameterList = {
            tokenId: tokenId,
            title: 'Mr.',
            leadFirstName: leadFirstName,
            emailId: email,
            mobileNo: mobileNumber,
            enquirySource: '',
            enquirySourceId: enquirySourceId,
            enquirySourceReference: '',
            subEnquirySource: '',
            subEnquirySourceId: '',
            leadAddress: '',
            projectName: projectName,
            subProjectName: '',
            vendorReferenceId: '',
            Probability: probability,
            Category: category,
            Type: type,
            IntSubProject: subProjectName,
            additionFieldList: [
                { name: 'Sub Source', value: subSource },
                { name: 'Medium Campaign', value: mediumCampaign },
                { name: 'Keywords', value: keywords },
            ],
        };

        try {
            const response = await fetch(serviceUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(jsonParameterList),
            });
            const result = await response.json();
            setLeadStatus(JSON.stringify(result[0]));
            setTokenId(''); // Clear token after submission
        } catch (error) {
            setLeadStatus('Error: ' + error.message);
        }
    };

    return (

        <div>
            <div className="btn-dual">
                <button onClick={() => setOpenPopup(true)} className="btn btn-fancy btn-medium btn-fast-blue margin-25px-right d-table d-sm-inline-block xs-margin-auto-lr xs-margin-15px-bottom">Book a site visit</button>
                <a href="floorplan" className="btn btn-fancy btn-medium btn-pink d-table d-sm-inline-block xs-margin-auto-lr">View floor plans</a>

                {
                    openPopup &&
               <>
                    <div className="white-popup-block col-10  p-0 mx-auto">
                    <button className="mfp-close" onClick={() => setOpenPopup(false)}>X</button>
            <div className="p-5 bg-white border-radius-6px xs-padding-six-all">
            <input
                        type="text"
                        value={tokenId}
                        onChange={(e) => setTokenId(e.target.value)}
                        className="medium-input margin-25px-bottom xs-margin-10px-bottom" 
                        placeholder="TokenId" 
                    />

                    <button className="btn btn-medium btn-fast-blue mb-5" onClick={getSecurityToken}>
                        Generate Token
                    </button>
                    <div>
                Token Status: <div className="text-fast-blue pt-3">{tokenStatus}</div>
            </div>
                    
                    <input
                        type="text"
                        value={leadFirstName}
                        onChange={(e) => setLeadFirstName(e.target.value)}
                       className="medium-input margin-25px-bottom xs-margin-10px-bottom" placeholder="Full Name"
                    />
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="medium-input margin-25px-bottom xs-margin-10px-bottom" placeholder="Email"
                    />
                    <input
                        type="text"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        className="medium-input margin-25px-bottom xs-margin-10px-bottom" placeholder="Mobile Number" 
                    />
                    
                    <button onClick={submitSalesLead} className="btn btn-medium btn-fast-blue mb-0">
                   Submit
                </button>
                <hr />
            <div>
                Lead Created Status: <div className="text-fast-blue">{leadStatus}</div>
            </div>

                   </div> </div>
                  
           
                    
                    </>
}

            </div>
        </div>
    );
};

export default Pform;
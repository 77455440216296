import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Navbar from './components/Navbar'

import Pform from './components/Pform';

function App() {
   
   
    return (
        <div>
            <Navbar></Navbar>

            <section className="p-0 position-relative mobile-height wow animate__fadeIn">
                <div className="opacity-extra-medium-2 bg-dark-gray border-radius-25px"></div>
                <video muted className="video-bg html-video" poster="img/banner.png">
                    <source type="video/mp4" src="video/video2.mp4" />
                    <source type="video/webm" src="video/video2.webm" />
                </video>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-11 col-lg-6 col-md-8 full-screen d-flex flex-column justify-content-center text-center md-landscape-h-600px">
                            <a href="javascript:void(0);" className="html-video-play video-icon-box video-icon-large position-relative margin-40px-bottom xs-margin-20px-bottom">
                                <span>
                                    <span className="video-icon bg-dark-gray">
                                        <i className="play-icon icon-simple-line-control-play text-white m-0" aria-hidden="true"></i>
                                        <i className="pause-icon icon-simple-line-control-pause text-white m-0 p-0" aria-hidden="true"></i>
                                        <span className="video-icon-sonar">
                                            <span className="video-icon-sonar-bfr bg-dark-gray opacity-7"></span>
                                            <span className="video-icon-sonar-afr bg-dark-gray"></span>
                                        </span>
                                    </span>
                                </span>
                            </a>
                            <h5 className="alt-font font-weight-700 text-white letter-spacing-1px margin-55px-bottom xs-margin-30px-bottom text-shadow-extra-large sm-no-text-shadow">Magnificent Spanish Row Villas with a private backyard.</h5>
                           
                                   <Pform/>
                               
                        </div>
                    </div>
                </div>
            </section>

            <section className="p-0 bg-extra-dark-gray wow animate__fadeIn">
                <div className="container-fluid">
                    <div className="d-flex flex-column">
                        <div className="row">
                        <img src="img/shade1.png" className='shade1'/>
                            <div className="col-12 col-md-6 bg-neon-blue order-1 justify-content-center d-flex flex-column wow animate__fadeIn" data-wow-delay="0.4s">
                                <div className="padding-6-rem-lr padding-8-rem-tb xl-padding-6-rem-tb xl-padding-4-rem-lr lg-padding-7-rem-lr sm-padding-6-rem-lr">
                                    <p className="text-fast-blue">Surya Valencia is a luxury <br />development with<br /> 122 Spanish Row Villas spread <br />over 9 pristine acres.</p>
                                    <p className="text-fast-blue"> 3258-3820 sft. Masterpieces</p>
                                    <p className="text-fast-blue"> G+2 & G+3 | 3.5 & 4.5 BHK</p>

                                </div>

                            </div>
                            <div className="col-12 p-0 col-md-6 bg-extra-dark-gray order-2 wow animate__fadeIn" data-wow-delay="0.4s" >
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s1.png' /></SwiperSlide>


                                </Swiper>

                            </div>
                        </div>
                        <div className="row">
                        <img src="img/shade3.png" className='shade3'/>
                            <div className="col-12 p-0 col-md-6 order-md-3 order-4 bg-extra-dark-gray order-1 wow animate__fadeIn" data-wow-delay="0.4s" >
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide><img src='img/s2.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s2.png' /></SwiperSlide>
                                    <SwiperSlide><img src='img/s2.png' /></SwiperSlide>


                                </Swiper>

                            </div>
                            <div className="col-12 col-sm-6 order-md-4 order-3 wow animate__fadeIn bg-light-pink" data-wow-delay="0.8s">
                                <div className="padding-6-rem-lr padding-8-rem-tb xl-padding-6-rem-tb xl-padding-4-rem-lr lg-padding-7-rem-lr sm-padding-6-rem-lr">

                                    <h5 className="font-weight-600 text-pink">Savour Al <br />fresco living.</h5>
                                    <p className="text-fast-blue">Every Villa has a wonderful private backyard.</p>
                                    <p className="text-fast-blue">Enjoy a BBQ with the family, or welcome the sunset with friends. Live the weekend life. With gardens, fountains, and beautiful little nooks to discover.</p>
                                    <span className="text-fast-blue border-all border-white p-3">Xx sft backyard</span>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                        <img src="img/shade5.png" className='shade5'/>
                            <div className="col-12 p-0 col-md-6  bg-light-yellow order-1 wow animate__fadeIn" data-wow-delay="0.4s" >
                                <div className="padding-6-rem-lr padding-8-rem-tb xl-padding-6-rem-tb xl-padding-4-rem-lr lg-padding-7-rem-lr sm-padding-6-rem-lr">

                                    <h5 className="font-weight-600 text-fast-blue">As exclusive  <br />as it gets</h5>
                                    <p className="text-fast-blue">30,000 sft of soul-<br />stirring Clubhouse.<br /> Everything the mind,<br /> body and soul needs.</p>

                                </div>



                            </div>
                            <div className="col-12 col-sm-6 order-1 order-sm-2 wow animate__fadeIn p-0" data-wow-delay="0.8s" >
                                <img src="img/s3.png" />
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section className="half-section">
                <div className="container">
                    <div className="row margin-5-half-rem-bottom align-items-center">
                        <div className="col-12 text-center wow animate__fadeIn" data-wow-delay="0.1s">
                            <h5 className="font-weight-600 text-fast-blue letter-spacing-1px ">Gallery</h5>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 p-0 wow animate__fadeIn" data-wow-delay="0.4s">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide><img src='img/g1.png' /></SwiperSlide>
                                <SwiperSlide><img src='img/g2.png' /></SwiperSlide>
                                <SwiperSlide><img src='img/g3.png' /></SwiperSlide>
                                <SwiperSlide><img src='img/g4.png' /></SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section className="half-section wow animate__fadeIn bg-fast-blue" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-yellow-ochre font-weight-600">Host, work, and entertain</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-7 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i1.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Double Height Lobby</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i2.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Multipurpose Hall</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i3.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Pantry</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i4.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Co-working Space</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i5.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Café</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i6.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Creche</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i7.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Kids Play Area</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i8.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Convenience Store</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i9.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Conference Room</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i10.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Party Terrace</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i11.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Barbeque Counter</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i12.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Guest Rooms</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i13.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Multipurpose Court</span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>







            <section className="half-section wow animate__fadeIn bg-light-gray" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-pink font-weight-600">For water lovers</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-5 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i14.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Pool Deck</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i15.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Swimming Pool</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i16.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Kids Pool</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i17.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Jacuzzi</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i18.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Paddle Pool</span>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>







            <section className="half-section wow animate__fadeIn bg-yellow-ochre" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-fast-blue font-weight-600">Staying active</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-5 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i19.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Gym</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i20.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Yoga Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i21.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Table Tennis Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i22.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Snooker/Billiards Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i23.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Indoor Games</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i24.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Badminton Court</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i25.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Outdoor Gym</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i26.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Play Lawn</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i27.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Golf Putting Zone</span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="half-section wow animate__fadeIn bg-light-gray" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-pink font-weight-600">Relax and rejuvenate</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-4 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i28.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Salon</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i29.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">AV Room</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i30.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Spa & Sauna</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i31.png" className="imgx padding-20px-bottom" />
                                    <span className="text-black d-block">Library</span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="half-section wow animate__fadeIn bg-pink" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center margin-seven-bottom">
                                    <h6 className="alt-font text-white font-weight-600">Gardens & parks</h6>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-7 justify-content-center">

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i32.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Butterfly Garden</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i33.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Meditation Garden</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i34.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Yoga / Activity Area</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i35.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Maze Walk</span>

                                </div>

                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i36.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Aroma Walk</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i37.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Congregation Lawn</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i38.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Focal Trellis</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i39.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Colanade Walk</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i40.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Floral Fountain</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i41.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Amphitheatre</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i42.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Open Party Area</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i43.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Trellis with Garden Sculpture</span>

                                </div>
                                <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s" >

                                    <img src="img/i44.png" className="imgx padding-20px-bottom" />
                                    <span className="text-white d-block">Senior Seating Alcove</span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>











            <section className="bg-light-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 text-center wow animate__fadeIn">
                            <h5 className="font-weight-600 text-fast-blue letter-spacing-1px ">Tucked away from the chaos, yet close to all that matters.</h5>
                            <p className="text-black font-weight-600">Located in close proximity to multiple Tech parks, Aero park, ITIR, Reputed hospitals, International schools and top colleges.</p>
                        </div>

                    </div>
                    <div className="row justify-content-center pt-5">
                        <div className="col-12 col-md-3 text-center wow animate__fadeIn">
                            <h2 className="font-weight-600 text-fast-blue letter-spacing-1px mb-0">20</h2>
                            <p className="text-fast-blue text-small mb-0">minutes to <br />International Airport.</p>
                            <img src="img/l1.png" className="margin-minus-40px-top" />
                        </div>
                        <div className="col-12 col-md-3 text-center wow animate__fadeIn">
                            <h2 className="font-weight-600 text-yellow-ochre letter-spacing-1px mb-0">25</h2>
                            <p className="text-yellow-ochre text-small mb-0">minutes to <br />Hebbal.</p>
                            <img src="img/l2.png" className="margin-minus-40px-top" />
                        </div>
                        <div className="col-12 col-md-3 text-center wow animate__fadeIn">
                            <h2 className="font-weight-600 text-pink letter-spacing-1px mb-0">35</h2>
                            <p className="text-pink text-small mb-0">minutes to <br />Manyata Tech Park.</p>
                            <img src="img/l3.png" className="margin-minus-40px-top" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="p-0">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12 p-0 col-md-6  bg-white order-1 wow animate__fadeIn" data-wow-delay="0.4s" >
                            <div className="padding-6-rem-lr padding-4-rem-tb xl-padding-4-rem-lr lg-padding-7-rem-lr sm-padding-6-rem-lr">

                                <h5 className="font-weight-600 text-fast-blue">The definition   <br />of class.</h5>
                                <p className="text-fast-blue">A palatial clubhouse, golf tee off greens, beautiful Spanish arbours.</p>
                                <a href="masterplan" className="btn btn-fancy btn-medium btn-fast-blue margin-25px-right d-table d-sm-inline-block xs-margin-auto-lr xs-margin-15px-bottom">View master plan</a><br /><br />
                                <a href="floorplan" className="btn btn-fancy btn-medium btn-pink d-table d-sm-inline-block xs-margin-auto-lr">View floor plans</a><br /><br />
                                <a href="clubplan" className="btn btn-fancy btn-small btn-fast-blue margin-25px-right d-table d-sm-inline-block xs-margin-auto-lr xs-margin-15px-bottom">View clubhouse plan</a>
                            </div>



                        </div>
                        <div className="col-12 col-sm-6 order-1 order-sm-2 wow animate__fadeIn p-0" data-wow-delay="0.8s" >
                            <img src="img/img2.png" />
                        </div>
                    </div>
                </div>
            </section>



            <footer className="footer-dark bg-black">

                <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-1"></div>
                            <div className="col-12 col-md-3 text-center sm-margin-20px-bottom">
                                <p className="text-white text-small">A flagship project by Surya Developers</p>
                                <a href="index.html" className="footer-logo"><img src="img/logo.png" data-at2x="img/logo.png" alt="" /></a>
                            </div>
                            <div className="col-6 col-md-3 last-paragraph-no-margin sm-margin-20px-bottom">
                                <p className="text-white text-small font-weight-600">HEAD OFFICE</p>
                                <p className="text-white text-small"># 684, 2nd Floor, HIG ‘A’ Sector, Opposite to Sheshadripuram College, Yelahanka New Town, Bengaluru - 560 064</p>
                            </div>
                            <div className="col-6 col-md-3 last-paragraph-no-margin sm-margin-20px-bottom">
                                <p className="text-white font-weight-600 text-small">GET IN TOUCH</p>
                                <p className="text-white text-small"> E-Mail: <a href="mailto:info@suryadevelopers.in">info@suryadevelopers.in</a> <br />Mob: <a href="tel:917411118484">+91 74 1111 8484</a> <br />Ph no: <a href="tel:918041228484">+91 80 4122 8484</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>



        </div>
    );
}

export default App;

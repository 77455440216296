import React, {useState} from 'react'
import Navbar from '../components/Navbar'

    function SalesLeadForm() {
        <Navbar></Navbar>
      const [tokenId, setTokenId] = useState('');
      const [leadFirstName, setLeadFirstName] = useState('');
      
      const [email, setEmail] = useState('');
     
      const [mobileNumber, setMobileNumber] = useState('');
      
      const [enquirySourceId, setEnquirySourceId] = useState('10');
      const [projectName, setProjectName] = useState('SuryaValencia Project');
      
     
      const [subSource, setSubSource] = useState('Housing.com');
      const [mediumCampaign, setMediumCampaign] = useState('Banner');
      const [keywords, setKeywords] = useState('2 BHK, 3 BHK');
      const [probability, setProbability] = useState('10');
      const [category, setCategory] = useState('1');
      const [type, setType] = useState('1');
      
      const [subProjectName, setSubProjectName] = useState('Surya Valencia');
      const [tokenStatus, setTokenStatus] = useState('');
      const [leadStatus, setLeadStatus] = useState('');
    
      // Function to fetch security token
      const getSecurityToken = async () => {
        const serviceUrl =
          'https://suryadevelopers.in4suite.com/SalesCustomerAPI/api/Lead/GetSecurityToken' +
          `?apiUser=surya&apiKey=surya@In4`;
    
        try {
          const response = await fetch(serviceUrl);
          const data = await response.text();
          setTokenStatus(data);
    
          const jsonOutput = JSON.parse(data);
          if (jsonOutput.tokenId) {
            setTokenId(jsonOutput.tokenId);
          }
        } catch (error) {
          setTokenStatus('Error: ' + error.message);
        }
      };
    
      // Function to submit sales lead data
      const submitSalesLead = async () => {
        if (!tokenId) {
          alert('Please generate the token before creating the sales lead.');
          return;
        }
    
        const serviceUrl =
          'https://suryadevelopers.in4suite.com/SalesCustomerAPI/api/Lead/CreateSalesLead';
    
        const jsonParameterList = {
          tokenId: tokenId,
          title: 'Mr.',
          leadFirstName: leadFirstName,
         
          emailId: email,
         
          mobileNo: mobileNumber,
          
          enquirySource: '',
          enquirySourceId: enquirySourceId,
          enquirySourceReference: '',
          subEnquirySource: '',
          subEnquirySourceId: '',
          leadAddress: '',
          projectName: projectName,
          subProjectName: '',
          
         
          vendorReferenceId: '',
          Probability: probability,
          Category: category,
          Type: type,
         
          IntSubProject: subProjectName,
          additionFieldList: [
            { name: 'Sub Source', value: subSource },
            { name: 'Medium Campaign', value: mediumCampaign },
            { name: 'Keywords', value: keywords },
          ],
        };
    
        try {
          const response = await fetch(serviceUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jsonParameterList),
          });
          const result = await response.json();
          setLeadStatus(JSON.stringify(result[0]));
          setTokenId(''); // Clear token after submission
        } catch (error) {
          setLeadStatus('Error: ' + error.message);
        }
      };
    
      return (
        <div style={{ backgroundColor: 'skyblue', padding: '20px' }}>
          <h2 style={{ fontSize: 'larger' }}>Sales Lead Testing</h2>
          <hr />
          <div>
            <label>
              Token Id:
              <input
                type="text"
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)}
                style={{ width: '500px' }}
              />
              <button onClick={getSecurityToken} style={{ width: '300px' }}>
                Generate Token
              </button>
            </label>
          </div>
          <br />
          <div>
            <label>
              Lead First Name:
              <input
                type="text"
                value={leadFirstName}
                onChange={(e) => setLeadFirstName(e.target.value)}
                style={{ width: '300px' }}
              />
            </label>
            <br />
          
            <br />
            <label>
              Email Id:
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '300px' }}
              />
            </label>
            <br />
           
            <br />
            <label>
              Mobile No:
              <input
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                style={{ width: '300px' }}
              />
            </label>
            <br />
           
          
           
        <button onClick={submitSalesLead} style={{ width: '300px', height: '20px' }}>
          Create Sales Lead
        </button>
      </div>
      <hr />
      <div>
        Token Status: <div>{tokenStatus}</div>
      </div>
      <hr />
      <div>
        Lead Created Status: <div>{leadStatus}</div>
      </div>
    </div>
  );
}

export default SalesLeadForm;
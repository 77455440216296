import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const FormThankYou = () => {
  return (
    <>
      {/* <Navbar></Navbar> */}

      <div className="form-thank-you bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 text-center p-5">
              <div className="thank-you-content border-all border-color-medium-gray p-5 margin-60px-tb">
                <div className="thank-you-icon mb-4">
                  <i className="fas fa-check-circle text-fast-blue" style={{ fontSize: "4rem" }}></i>
                </div>

                <h4 className="alt-font text-fast-blue font-weight-600 margin-30px-bottom">Thank You!</h4>

                <p className="text-black margin-15px-bottom">
                  Your message has been received successfully. One of our representatives will get in touch with you shortly.
                </p>

                <p className="text-black margin-30px-bottom">We appreciate your interest in Surya Valencia luxury villas.</p>

                <Link to="/" className="btn btn-medium btn-fast-blue">
                  Back to Home
                </Link>
              </div>

              <div className="contact-info margin-40px-top">
                <p className="text-black">
                  No.684, 2nd floor, HIG 'A' Sector,
                  <br />
                  Opposite to Sheshadripuram College,
                  <br />
                  Above State Bank of Hyderabad,
                  <br />
                  Yelahanka NewTown, Bangalore - 560064
                </p>
                <p className="text-black">
                  Email:{" "}
                  <a href="mailto:info@suryadevelopers.in" className="text-fast-blue">
                    info@suryadevelopers.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="footer-dark bg-black padding-30px-tb">
          <div className="container">
            <div className="row align-items-center justify-content-center d-flex">
              <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  <img src="img/logo.png" alt="Surya Developers" />
                </a>
              </div>
              <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  <img src="img/footer-logo.svg" alt="Surya Valencia" />
                </a>
              </div>

              <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-small">
                  <a className="text-white" href="#">
                    PRIVACY POLICY
                  </a>
                </p>
              </div>
              <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-small">
                  <a className="text-white" href="#">
                    TC's
                  </a>
                </p>
              </div>
              <div className="col-12 col-md-2 text-center sm-margin-20px-bottom">
                <p className="text-white font-weight-600 text-small">Copyright 2025 Surya</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FormThankYou;

import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Modal from "../components/Modal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Demo() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div>
      <Navbar></Navbar>

      <section className="p-0 position-relative one-fourth-screen wow animate__fadeIn bg-white">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 p-0 d-flex flex-column justify-content-center text-center md-landscape-h-600px">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img alt="" src="img/bn1.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="" src="img/bn2.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="" src="img/bn3.jpg" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-0">
        <div className="container">
          <div className="row justify-content-center d-flex align-items-center">
            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
              <h5 className="font-weight-600 letter-spacing-1px text-fast-blue mb-5">OVERVIEW OF PROJECT</h5>

              <ul className="list-style-06">
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.2s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <span className="text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">Healthy profits for long term</span>
                    <p className="w-80 lg-w-90 xs-w-100">Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.</p>
                  </div>
                </li>
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.4s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <span className="text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">Working towards your business</span>
                    <p className="w-80 lg-w-90 xs-w-100">Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.</p>
                  </div>
                </li>
                <li className="border-radius-6px margin-35px-bottom last-paragraph-no-margin wow animate__fadeIn" data-wow-delay="0.4s">
                  <div>
                    <span className="w-25px h-25px text-center bg-fast-blue rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <div>
                    <span className="text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">Advanced customization options</span>
                    <p className="w-80 lg-w-90 xs-w-100">Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-12 mt-3">
                  {" "}
                  <ul className="blog-masonry blog-wrapper grid grid-1col">
                    <li className="grid-sizer"></li>

                    <li className="grid-item wow animate__fadeIn">
                      <div className="blog-post text-center">
                        <div className="blog-post-image">
                          <img alt="" src="img/image4.jpg" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="half-section wow animate__fadeIn">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5 className="mb-5 letter-spacing-1px font-weight-600 text-fast-blue">LANDMARKS BY CATEGORIES:</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="text-black text-extra-large font-weight-600">School/College</h6>
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Presidency University - 10 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Vidyasilip University - 20 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Stonehill International School - 20 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Nitte Meenakshi Institute of technology</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Harrow International School - 30 mins</span>
                    </li>
                  </ul>

                  <h6 className="text-black text-extra-large font-weight-600">Hospitals</h6>
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Manipal hospital North - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Cytecare Hospitals - 30 mins</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h6 className="text-black text-extra-large font-weight-600">Transportation Connectivity</h6>{" "}
                  <ul className="list-style-02">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Kempegowda International Airport - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>

                      <span className="text-black"> Yelahanka Railway Station</span>
                    </li>
                  </ul>
                  <h6 className="text-black text-extra-large font-weight-600">Commercial</h6>
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Prestige Tech Cloud - 20 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Brigade magnum - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black"> L&T tech Park- 30 mins</span>
                    </li>
                  </ul>
                  <h6 className="text-black text-extra-large font-weight-600">Sports</h6>
                  <ul className="list-style-02">
                    <li className="margin-20px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black"> Padukone Dravid Center for Excellence - 20 mins</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-12">
                  <h6 className="text-black text-extra-large font-weight-600">Lesiure & Entrtainment</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Spree Resort - 10 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Silver Oak Resort - 10 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Park Inn & Suites by Radisson - 10 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Angsana Oasis Spa & Resort - 10 mins</span>
                    </li>

                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Ramanashree California Resort - 20 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Dome Retreats - 10 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Moxy Bengaluru Airport - 20 mins</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-style-02 mb-5">
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Rico Wine Park & Vineyards - 20 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Terraform Arena - 20 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Club Cabana - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Royal Orchid Resorts - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Hesaraghatta Grasslands Hiking - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Dmart - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Phoenix Mall of Asia - 30 mins</span>
                    </li>
                    <li className="margin-10px-bottom">
                      <i
                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right"
                        aria-hidden="true"
                      ></i>
                      <span className="text-black">Bloom Hotel - 30 mins </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="half-section wow animate__fadeIn bg-fast-blue">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h5 className="font-weight-600 text-white mb-5 letter-spacing-1px">USP</h5>
            </div>
          </div>
          <div className="row justify-content-center d-flex">
            <div className="col-md-4">
              <ul className="list-style-01 text-white">
                <li>
                  <i className="fas fa-check text-white"></i>Provision for lift in every villa
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>Backyard for every villa
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>20,000 sq. ft. upcoming clubhouse
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-style-01 text-white">
                <li>
                  <i className="fas fa-check text-white"></i>Open Backyard
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>Large Terraces (not 1 but 2)
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>

                <li>
                  <i className="fas fa-check text-white"></i>Spanish villa
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-style-01 text-white">
                <li>
                  <i className="fas fa-check text-white"></i>Upcoming Location with leisure lifestyle and promise of future growth
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
                <li>
                  <i className="fas fa-check text-white"></i>All bedrooms with balconies and walk-in wardrobes
                  <span className="list-hover bg-black box-shadow-small border-radius-5px"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="half-section ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h5 className="font-weight-600 text-fast-blue mb-5 letter-spacing-1px">AMENITIES</h5>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div
                className="panel-group accordion-event accordion-style-03"
                id="accordion3"
                data-active-icon="fa-angle-down"
                data-inactive-icon="fa-angle-right"
              >
                <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.2s">
                  <div className="panel-heading active-accordion">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-01"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">KIDS:</span>
                        <i className="indicator fas fa-angle-down text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div id="accordion-style-03-01" className="panel-collapse collapse show" data-parent="#accordion3">
                    <div className="panel-body">
                      <div className="row row-cols-3 row-cols-md-7 justify-content-center">
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/7.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Kids Play Area</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/11.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Kids Pool</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/18.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Play Lawn</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/36.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Maze Walk</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/1.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Double Height Lobby</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/2.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Multipurpose Hall</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/3.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Pantry</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.4s">
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-02"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">CLUBHOUSE:</span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div id="accordion-style-03-02" className="panel-collapse collapse" data-parent="#accordion3">
                    <div className="panel-body">
                      <div className="row row-cols-3 row-cols-md-7 justify-content-center">
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/35.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Yoga/Activity Area</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/37.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Aroma Walk</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/38.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Congregation Lawn</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/39.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Focal Trellis</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/40.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Colanade Walk</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/41.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Floral Fountain</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/42.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Amphitheatre</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.6s">
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-03"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">FITNESS / FRESHNESS:</span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div id="accordion-style-03-03" className="panel-collapse collapse" data-parent="#accordion3">
                    <div className="panel-body">
                      <div className="row row-cols-3 row-cols-md-7 justify-content-center">
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/28.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Conference Room</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/29.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Party Terrace</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/30.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Barbeque Counter</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/31.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Guest Rooms</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/32.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Multipurpose Court</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/33.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Butterfly Garden</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/34.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Meditation Garden</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.6s">
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-04"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">SENIOR CITIZEN:</span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div id="accordion-style-03-04" className="panel-collapse collapse" data-parent="#accordion3">
                    <div className="panel-body">
                      <div className="row row-cols-3 row-cols-md-7 justify-content-center">
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/4.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Co-working Space</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/5.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Cafe</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/6.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Creche</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/8.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Convenience Store</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/9.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Pool Deck</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/10.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Swimming Pool</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/12.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">J Acuzzi</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.6s">
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-05"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">ENTERTAINTMENT:</span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div id="accordion-style-03-05" className="panel-collapse collapse" data-parent="#accordion3">
                    <div className="panel-body">
                      <div className="row row-cols-3 row-cols-md-7 justify-content-center">
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/13.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Paddle Pool</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/14.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Toilets</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/15.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Gym</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/16.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Outdoor Gym</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/17.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Yoga Room</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/19.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Table Tennis Room</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/20.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Snooker/Billiards Room</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.6s">
                  <div className="panel-heading">
                    <a
                      className="accordion-toggle collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#accordion-style-03-06"
                      aria-expanded="false"
                    >
                      <div className="panel-title">
                        <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">WORKING:</span>
                        <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                      </div>
                    </a>
                  </div>
                  <div id="accordion-style-03-06" className="panel-collapse collapse" data-parent="#accordion3">
                    <div className="panel-body">
                      <div className="row row-cols-3 row-cols-md-7 justify-content-center">
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/21.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Indoor Games</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/22.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Badminton Court</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/23.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Salon</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/24.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">AV Room</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/25.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Spa & Sauna</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/26.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Library</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/27.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Golf Putting Zone</span>
                        </div>

                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/43.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Open Party Area</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/44.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Trellis with Garden Sculpture</span>
                        </div>
                        <div className="col text-center margin-50px-bottom wow animate__fadeIn" data-wow-delay="0.1s">
                          <img alt="" src="img/45.jpg" className="imgx padding-20px-bottom" />
                          <span className="text-fast-blue d-block">Senior Seating Alcove</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="half-section pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center wow animate__fadeIn" data-wow-delay="0.1s">
              <h5 className="mb-5 font-weight-600 text-fast-blue letter-spacing-1px ">GALLERY</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0 wow animate__fadeIn text-center" data-wow-delay="0.4s">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img alt="" src="img/g1.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="" src="img/g2.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="" src="img/g3.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="" src="img/g4.png" />
                  <h6 className="text-black">Lorem Ipsum Dolor</h6>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="half-section bg-light-gray wow animate__fadeIn" data-wow-delay="0.4s">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center wow animate__fadeIn" data-wow-delay="0.1s">
              <h5 className="mb-5 font-weight-600 text-fast-blue letter-spacing-1px ">FLOOR PLAN</h5>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 margin-30px-bottom xs-margin-15px-bottom">
              <img alt="" src="img/4bh.jpg" className="mb-4" />
              <h5 className="mb-5 alt-font text-fast-blue font-weight-600 letter-spacing-minus-1px">4 BHK</h5>

              <ul className="list-style-02">
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">Saleable Area - 3120 sqft. - 3343 sqft.</span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">2 types of villas </span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black"> West Facing Plot - North Facing Villa </span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">East Facing Plot - East Facing Villa.</span>
                </li>
              </ul>
            </div>

            <div className="col-md-6 margin-30px-bottom xs-margin-15px-bottom">
              <img alt="" src="img/5bh.jpg" className="mb-4" />
              <h5 className="alt-font mb-5 text-fast-blue font-weight-600 letter-spacing-minus-1px">5 BHK</h5>

              <ul className="list-style-02">
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">Saleable Area - 3869 sqft. - 3960 sqft. </span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">3 types of villas </span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black"> West Facing Plot - North Facing Villa </span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">East Facing Plot - East Facing Villa</span>
                </li>
                <li className="margin-10px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black">North Facing Plot - North Facing Villa.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="half-section bg-neon-blue">
        <div className="container">
          <div className="row justify-content-center d-flex align-items-center">
            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
              <h5 className="mb-5 letter-spacing-1px font-weight-600 text-fast-blue">
                WHY CHOOSING <br />
                VILLA OVER APARTMENT
              </h5>
              <ul className="list-style-02">
                <li className="margin-20px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black"> Let's collaborate make an impact in the business</span>
                </li>
                <li className="margin-20px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black"> A satisfied customer is the best business starts</span>
                </li>
                <li className="margin-20px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black"> Expertise to build the things that matter for you</span>
                </li>
                <li className="margin-20px-bottom">
                  <i className="feather icon-feather-arrow-right-circle text-large text-fast-blue position-relative margin-10px-right" aria-hidden="true"></i>
                  <span className="text-black"> Expertise to build the things that matter for you</span>
                </li>
              </ul>
              <p className="text-black">
                Surya Developers is a company founded on trust and expertise, with over 20 years of experience in real estate development and project
                administration. Our team of professionals is dedicated to delivering exceptional service and ensuring timely completion of every project.
              </p>
            </div>

            <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.4s">
              <img alt="" className="w-100" src="img/s1.png" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center d-flex align-items-center">
            <div className="col-md-6">
              <h5 className="text-fast-blue font-weight-600">CONTACT US</h5>
              <div className="mb-5 border-all border-color-medium-gray p-5">
                <input type="text" className="medium-input margin-25px-bottom xs-margin-10px-bottom" placeholder="Full Name" />
                <input type="text" className="medium-input margin-25px-bottom xs-margin-10px-bottom" placeholder="Email" />
                <input type="text" className="medium-input margin-25px-bottom xs-margin-10px-bottom" placeholder="Mobile Number" />

                <button className="btn btn-medium btn-fast-blue mb-0">Submit</button>
              </div>
              <p className="text-black">
                No.684,2nd floor, HIG 'A' Sector,
                <br />
                Opposite to Sheshadripuram College,
                <br />
                Above State Bank of Hyderabad,
                <br /> Yelahanka NewTown, Bangalore - 560064.
              </p>
              <p className="text-black">E-Mail : info@suryadevelopers.in</p>
            </div>
            <div className="col-md-6">
              <img alt="" src="img/location.svg" />
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-dark bg-black">
        <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
          <div className="container">
            <div className="row align-items-center justify-content-center d-flex">
              <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  <img alt="" src="img/logo.png" />
                </a>
              </div>
              <div className="col-6 col-md-2 text-center sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  <img src="img/footer-logo.svg" alt="" />
                </a>
              </div>

              <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-small">
                  {" "}
                  <p className="text-white">PRIVACY POLICY</p>{" "}
                </p>
              </div>
              <div className="col-6 col-md-3 text-center sm-margin-20px-bottom">
                <p className="text-small">
                  {" "}
                  <p className="text-white">TC's</p>{" "}
                </p>
              </div>
              <div className="col-12 col-md-2 text-center sm-margin-20px-bottom">
                <p className="text-white font-weight-600 text-small">Copyright2025Surya</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div>
        <button onClick={openModal} className=" floating-button">
          ENQUIRE
        </button>

        <Modal isOpen={isModalOpen} closeModal={closeModal}>
          <div className="padding-ten-all bg-white border-radius-6px xs-padding-six-all">
            <h6 className="text-extra-dark-gray text-center font-weight-500 margin-35px-bottom xs-margin-15px-bottom">ENQUIRE NOW</h6>
            <div>
              <input className="medium-input margin-25px-bottom xs-margin-10px-bottom required" type="text" name="name" placeholder="Your name" />
              <input className="medium-input margin-25px-bottom xs-margin-10px-bottom required" type="email" name="email" placeholder="Your email address" />
              <input className="medium-input margin-25px-bottom xs-margin-10px-bottom" type="tel" name="phone" placeholder="Your mobile" />
              <button className="btn btn-medium btn-fast-blue mb-0 submit" type="submit">
                SUBMIT
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Demo;
